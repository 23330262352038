import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { getArticleById } from "../../api/contentApi";

const ArticleDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState(null);
  const language = "en";

  useEffect(() => {
    fetchArticle();
  }, []);

  const fetchArticle = async () => {
    try {
      const response = await getArticleById(id, language);
      setArticle(response);
    } catch (error) {
      navigate("/not-found");
    }
  };

  if (!article) return <p>Loading...</p>;

  return (
    <Wrapper>
      <ImageWrapper>
        <img src={article.image} alt={article.title} />
      </ImageWrapper>
      <Content>
        <CategoryTag>{article.category}</CategoryTag>
        <h1>{article.title}</h1>
        <AuthorInfo>
          <img src="/avatar.png" alt="Author" />
          <span>{article.date}</span>
        </AuthorInfo>
        <ArticleContent dangerouslySetInnerHTML={{ __html: article.content }} />
        <BackButton onClick={() => navigate(-1)}>← Back to Articles</BackButton>
      </Content>
    </Wrapper>
  );
};

export default ArticleDetail;

const Wrapper = styled.div`
  max-width: 900px;
  margin: 40px auto;
  padding: 20px;
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  text-align: left;
`;

const CategoryTag = styled.span`
  background: #6a0dad;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 14px;
  display: inline-block;
  margin-bottom: 10px;
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  color: #555;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
`;

const ArticleContent = styled.div`
  margin-top: 20px;
  font-size: 16px;
  color: #333;
`;

const BackButton = styled.button`
  margin-top: 20px;
  background: #6a0dad;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: #4b0082;
  }
`;
