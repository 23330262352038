import React, { useEffect, useState } from "react";
import { getCategoriesAndContentNumber } from "../../api/contentApi";
import styled from "styled-components";

const CategoriesIntro = () => {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState("all");
  const [totalArticles, setTotalArticles] = useState(0);

  const Number = true;
  const language = "en";

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getCategoriesAndContentNumber({
        Number,
        language,
      });

      if (response) {
        let total = response.reduce(
          (acc, category) => acc + category.articles_count,
          0
        );
        setTotalArticles(total);

        const formattedCategories = response.map((category) => {
          const translation = category.translations.find(
            (t) => t.locale === language
          );
          return {
            id: category.id,
            name: translation ? translation.name : category.name,
            articles_count: category.articles_count,
          };
        });

        setCategories(formattedCategories);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des catégories :", error);
    }
  };

  return (
    <Wrapper id="category">
      <div className="whiteBg">
        <div className="container">
          <div className="text-center my-6">
            <h2 className="text-2xl font-bold">Browse by Category</h2>
            <p className="text-gray-500">
              Select a category to see more related content
            </p>
            <ButtonsRow>
              <CategoryButton
                active={activeCategory === "all"}
                onClick={() => setActiveCategory("all")}
              >
                All ({totalArticles})
              </CategoryButton>

              {categories.map((category) => (
                <CategoryButton
                  key={category.id}
                  active={activeCategory === category.id}
                  onClick={() => setActiveCategory(category.id)}
                >
                  {category.name} ({String(category.articles_count).padStart(2, "0")})
                </CategoryButton>
              ))}
            </ButtonsRow>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default CategoriesIntro;

const Wrapper = styled.section`
  width: 100%;
  padding: 40px 0;
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px; /* Ajout d'un espace uniforme entre les boutons */
  padding: 10px;
`;

const CategoryButton = styled.button`
  background: ${({ active }) => (active ? "#6a0dad" : "#ffffff")}; 
  border: 2px solid ${({ active }) => (active ? "#6a0dad" : "#d1d5db")}; 
  color: ${({ active }) => (active ? "white" : "#000")};
  padding: 12px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 6px; 

  &:hover {
    background: ${({ active }) => (active ? "#4b0082" : "#f3f4f6")}; /* Effet hover */
  }
`;
