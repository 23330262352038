import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getArticles } from "../../api/contentApi";
import { Link } from "react-router-dom";

const ArticlesGrid = () => {
  const [articles, setArticles] = useState([]);
  const language = "en"; // Langue à utiliser

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const response = await getArticles(6, language);
      setArticles(response);
    } catch (error) {
      console.error("Erreur lors de la récupération des articles :", error);
    }
  };

  return (
    <Wrapper>
      <Grid>
        {articles.map((article) => (
          <Card key={article.id}>
          <Link to={`/article/${article.id}`}>
            <ImageWrapper>
              <img src={article.image} alt={article.title} />
            </ImageWrapper>
          </Link>
          <CardContent>
            <h3>
              <Link to={`/article/${article.id}`}>{article.title}</Link>
            </h3>
            <p>{article.excerpt}</p>
            <AuthorInfo>
              <img src="/avatar.png" alt="Author" />
              <span>{article.author} · {article.date}</span>
              <CategoryTag>{article.category}</CategoryTag>
            </AuthorInfo>
          </CardContent>
        </Card>
        ))}
      </Grid>

      <ButtonContainer>
        <ViewAllButton>All Articles</ViewAllButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default ArticlesGrid;

const Wrapper = styled.div`
  text-align: center;
  padding: 5px 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1100px;
  margin: 20px auto;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CardContent = styled.div`
  padding: 15px;

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    color: #666;
  }
`;

const AuthorInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  span {
    font-size: 14px;
    color: #555;
  }
`;

const CategoryTag = styled.span`
  background: #6a0dad;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const ViewAllButton = styled.button`
  background: #6a0dad;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: #4b0082;
  }
`;
