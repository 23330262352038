import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Pricing from "../components/Sections/Pricing";
import Contact from "../components/Sections/Contact";
import Footer from "../components/Sections/Footer"
import CategoriesIntro from "../components/Sections/CategoriesIntro";
import ArticlesGrid from "../components/Sections/ArticlesGrid";

export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      {/* <Services /> */}
      <CategoriesIntro />
      <ArticlesGrid />
      <Contact />
      <Footer />
    </>
  );
}


