import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getMenus } from "../../api/contentApi";
import { Link } from "react-scroll";
// Components
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import LogoIcon from "../../assets/svg/Logo";
import BurgerIcon from "../../assets/svg/BurgerIcon";

export default function TopNavbar() {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);
  const [menus, setMenus] = useState([]);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState("fr"); // État pour la langue

  // Gestion du scroll pour changer la hauteur de la navbar
  useEffect(() => {
    const handleScroll = () => setY(window.scrollY);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [y]);

  // Récupération des menus depuis le backend
  useEffect(() => {
    getMenus()
      .then(setMenus)
      .catch((err) => setError(err));
  }, []);

  // Fonction pour basculer la langue
  const toggleLanguage = () => {
    setLanguage((prevLanguage) => (prevLanguage === "fr" ? "en" : "fr"));
  };

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <Wrapper
        className="flexCenter animate whiteBg"
        style={y > 100 ? { height: "60px" } : { height: "80px" }}
      >
        <NavInner className="container flexSpaceCenter">
          <Link className="pointer flexNullCenter" to="home" smooth={true}>
            <LogoIcon />
            <h1 style={{ marginLeft: "15px" }} className="font20 extraBold">
              Fanatic
            </h1>
          </Link>
          <BurderWrapper
            className="pointer"
            onClick={() => toggleSidebar(!sidebarOpen)}
          >
            <BurgerIcon />
          </BurderWrapper>
          {/* Gestion de l'affichage des menus dynamiques */}
          <UlWrapper className="flexNullCenter">
            {error ? (
              <li className="semiBold font15">❌ Erreur chargement menus</li>
            ) : menus.length > 0 ? (
              menus.map((menu) => (
                <li key={menu.id} className="semiBold font15 pointer">
                  <Link
                    activeClass="active"
                    style={{ padding: "10px 15px" }}
                    to={menu.slug}
                    spy={true}
                    smooth={true}
                    offset={-80}
                  >
                    {menu.name}
                  </Link>
                </li>
              ))
            ) : (
              <li className="semiBold font15">Chargement des menus...</li>
            )}
          </UlWrapper>
          <UlWrapperRight className="flexNullCenter">
            <li className="semiBold font15 pointer flexCenter">
              <SwitchContainer>
                <input
                  type="checkbox"
                  id="languageSwitch"
                  checked={language === "en"}
                  onChange={toggleLanguage}
                />
                <label htmlFor="languageSwitch" className="switch-label">
                  <span className="on">EN</span>
                  <span className="off">FR</span>
                </label>
              </SwitchContainer>
            </li>
          </UlWrapperRight>
        </NavInner>
      </Wrapper>
    </>
  );
}

// Styles pour le bouton switch moderne
const SwitchContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch-label {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: white;
  }

  .switch-label .on,
  .switch-label .off {
    transition: opacity 0.3s ease;
  }

  .switch-label .on {
    opacity: ${({ checked }) => (checked ? 1 : 0)};
  }

  .switch-label .off {
    opacity: ${({ checked }) => (checked ? 0 : 1)};
  }

  input:checked + .switch-label {
    background-color: #2196f3;
  }

  input:checked + .switch-label .on {
    opacity: 1;
  }

  input:checked + .switch-label .off {
    opacity: 0;
  }
`;

const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
`;
const NavInner = styled.div`
  position: relative;
  height: 100%;
`;
const BurderWrapper = styled.button`
  outline: none;
  border: 0px;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;
const UlWrapper = styled.ul`
  display: flex;
  @media (max-width: 760px) {
    display: none;
  }
`;
const UlWrapperRight = styled.ul`
  @media (max-width: 760px) {
    display: none;
  }
`;