import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "./screens/Landing";
import ArticleDetails from "./components/Sections/ArticleDetails";
import NotFound from "./screens/NotFound";
import ArticlesGrid from "./components/Sections/ArticlesGrid";

const App= () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/article/:id" element={<ArticleDetails />} />
        <Route path="/articles" element={<ArticlesGrid />} /> 
      </Routes>
    </Router>
  );
}

export default App;
